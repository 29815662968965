<template>
    <div class="shareExtension">
        <van-overlay class="initLoading" :show="initLoading" :lock-scroll="true">
            <van-loading color="#F0D19E" type="spinner" />
        </van-overlay>
        <img class="bg" src="../../sprite/share-bg.jpg" alt="">
        <div class="joinText" v-if="!isAuthorized">您的好友&nbsp;<span> {{nickName}} </span> &nbsp;邀请您加入</div>
        <div class="content">
            <img src="../../sprite/share-content.jpg" alt="">
            <span class="more" @click="goUrl">查看更多热销商品</span>
        </div>
        <div class="bottomJoin" v-if="!isAuthorized">
            <div class="btn" @click="goLogin"></div>
            <div class="refuse" @click="$router.push('./')">拒绝协议</div>
            <div class="agreement">请仔细阅读以下协议：<span @click="$router.push('/serveAgreement')">《酷联盟服务协议》</span>、<span @click="$router.push('/commissionAgreement')">《推广人佣金与行为规则》。</span></div>
        </div>
        <div class="joinTab" v-else>
            <p class="tipText">您已成为酷联盟</p>
            <div @click="$router.push('/')" class="button">立即赚钱</div>
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs'
import { Toast } from 'vant';
import './index.less'
import { getToken, urlParse, isMangguoApp } from '../../utils/tokenUtils'
import { 
  GetUserInfo,
  GetProductList,
  CreateUserInfo,
  GetProductShareUrl} from '../../service/home'
export default {
    data(){
        return {
            nickName:'',
            isVisible:false,
            userInfo:null,
            isAuthorized:false,
            urlData:'',
            isOutIn:false,
            productList:[],
            initLoading:true,
        }
    },
    created() {
        this.urlData = urlParse(window.location.href)
        this.nickName = this.urlData.nickName
    },
    mounted() {
        console.log("start")
        this.getUseToken()
        this.getProductList();
    },
    methods:{
        goLogin(){
            const _this = this
            if(localStorage.getItem('token')){
                this.createUserInfo();
                return;
            }
            let url = window.location.href.split('#')[0]+"#/?superiorId="+this.urlData.superiorId
            window.location.href = `https://t.youku.com/yep/page/m/jca2kbelhq?wh_weex=true&isNeedBaseImage=1&redirect_url=${
                process.env.NODE_ENV == 'development'? encodeURIComponent(url): encodeURIComponent(url)
            }`
        },
        getUseToken(){
            const _this = this
            if(!this.isOutIn){
                localStorage.removeItem('token')
            }
        },
        getUserInfo(){
            GetUserInfo().then((res)=>{
                const { code,data,message } = res
                if(code == '0'){
                    console.log(data);
                    this.userInfo = data;
                    localStorage.setItem('nickName',data.nickName)
                    localStorage.setItem('avatarUrl',data.avatarUrl)
                    localStorage.setItem('channelCode',data.channelCode)
                    localStorage.setItem('mgUuid',data.mgUuid)
                    localStorage.setItem('id',data.id)
                    localStorage.setItem('shareId',data.shareId)
                    localStorage.setItem('superiorId',data.superiorId)
                    localStorage.setItem('userLevel',data.userLevel)
                    localStorage.setItem('isDisable',0)
                }else if(code == '402'){
                    this.userInfo = data;
                    this.createUserInfo()
                }else if(code == '-1' && message == "账号已被禁用"){
                    this.isDisable = true;
                    this.userInfo = data;
                    localStorage.setItem('nickName',data.nickName)
                    localStorage.setItem('avatarUrl',data.avatarUrl)
                    localStorage.setItem('channelCode',data.channelCode)
                    localStorage.setItem('mgUuid',data.mgUuid)
                    localStorage.setItem('id',data.id)
                    localStorage.setItem('shareId',data.shareId)
                    localStorage.setItem('superiorId',data.superiorId)
                    localStorage.setItem('userLevel',data.userLevel)
                    localStorage.setItem('isDisable',1)
                    Toast(message)
                }
            })
        },
        createUserInfo(){
            CreateUserInfo({
                token:localStorage.getItem('token'),
                channelCode:this.urlData.channelCode,
                superiorId:this.urlData.superiorId,
                shareId:this.urlData.shareId,
            }).then((res)=>{
                const { code, data } = res
                if(code == '0'){
                    localStorage.setItem('nickName',data.nickName)
                    localStorage.setItem('avatarUrl',data.avatarUrl)
                    localStorage.setItem('channelCode',data.channelCode)
                    localStorage.setItem('mgUuid',data.mgUuid)
                    localStorage.setItem('id',data.id)
                    localStorage.setItem('shareId',data.shareId)
                    localStorage.setItem('superiorId',data.superiorId)
                    localStorage.setItem('userLevel',data.userLevel)
                    this.isVisible = false;
                }
            })
        },
        getProductList(){
            GetProductList({
                pageIndex:this.pageIndex,
                pageSize:this.pageSize,
                userId:0
            }).then((res)=>{
                const { code,data } = res
                if(code == '0'){
                    console.log(data.list)
                    this.productList = data.list;
                }
                this.initLoading = false;
            })
        },
        viewAgreement (e) {
            this.$router.push(e)
        },
        goUrl(){
            this.$router.push({
                path: '/',
                query: {
                    shareId:this.urlData.shareId,
                }
            })
        }
    }
}
</script>
<style lang="less" scoped></style>